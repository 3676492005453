export default function (isoDate: string): string {
	const regex: RegExp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;

	if (!regex.test(isoDate)) return isoDate;

	const date: Date = new Date(isoDate);

	const day: string = String(date.getDate()).padStart(2, "0");
	const month: string = String(date.getMonth() + 1).padStart(2, "0");
	const year: number = date.getFullYear();

	const hours: string = String(date.getHours()).padStart(2, "0");
	const minutes: string = String(date.getMinutes()).padStart(2, "0");

	return `${day}.${month}.${year}, ${hours}:${minutes}`;
}
